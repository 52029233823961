import React from "react";
import { Row, Col, Input, Tooltip, Button } from "antd";
import {
  ClockCircleOutlined,
  DollarCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

//Estilos Module y SCSS
import "./TicketSearch.scss";
import curStyle from "../../../css/GlobalCmp.module.css";

// Importacion de contantes
import { colorSec } from "../../../constants";

export default function TicketSearch(props) {
  const { onchangeSearch } = props;
  const { Search } = Input;

  return (
    <Row className="ticket-search">
      <Col span={24}>
        <Search
          placeholder="BUSCAR PRODUCTOS"
          onSearch={(value) => onchangeSearch(value)}
          enterButton
          allowClear
          size="large"
        />
      </Col>
    </Row>
  );
}
