import React from "react";
import { Row, Col, Card } from "antd";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

import bg3 from "../../../assets/img/bg-3.jpg";

import "./CardDetails.scss";

export default function CardDetails(props) {
  const { closeModal } = props;

  const { Meta } = Card;

  const product = useSelector((state) => state.currentProduct.currentProduct);

  console.log(product);

  const gridStyle = {
    width: '100%',
    textAlign: 'center',
  };

  return (
    <div
      className="loading"
      style={{
        backgroundImage: `url('${bg3}')`,
        height: "100%",
        backgroundSize: "cover",
        width: "660px",
        marginTop: 20,
      }}
      onClick={() => closeModal()}
    >
      <Row>
        <Col style={{ alignItems: "center" }}>
        <Card
            style={{ width: 600 }}
            cover={
              <img
                alt={product.nombre_proveedor}
                src={"https://shk.aal-estate.com/files/proveedores/"+product.tarjeta}
              />
            }
            actions={[
              <Card.Grid style={gridStyle}>Teléfono: <b>{product.telefono_proveedor}</b></Card.Grid>,
              <Card.Grid style={gridStyle}>Email: <b>{product.email_proveedor}</b></Card.Grid>,
            ]}
          >
            <Meta
              title={product.nombre_proveedor}
              description={product.direccion}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
