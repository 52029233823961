import React, { useState } from "react";
import { Layout } from "antd";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Importo Store y Provider desde Redux
import store from "./store";
import { Provider } from "react-redux";

import "./App.css";

//Paginas
import Loading from "./components/Loading";
import Productos from "./pages/Shk/Productos";
import Error404 from "./pages/error404";

export default function App() {
  const { Content } = Layout;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Provider store={store}>
      <Layout>
        <Router>
          <Content>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Switch>
                  <Route path="/" exact={true}>
                    <Productos setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="*">
                    <Error404 />
                  </Route>
                </Switch>
              </>
            )}
          </Content>
        </Router>
      </Layout>
    </Provider>
  );
}