import React from "react";
import { Col, Card, Typography } from "antd";
import {
  PictureOutlined,
  ShoppingCartOutlined,
  IdcardOutlined,
} from "@ant-design/icons";

import "./Product.scss";

export default function Product(props) {
  const {
    product,
    showDetails,
    showCard,
  } = props;
  const { Meta } = Card;
  const { Title } = Typography;
  const len_dscr = product.informacion.length;

  return (
    <Col xs={12} sm={8} md={6} lg={6} xl={6} className="myproduct">
      <Card
        style={{ marginTop: 16, marginBottom: 15 }}
        hoverable
        actions={[
          <IdcardOutlined
            key="tarjeta"
            onClick={() => showCard(product)}
          />,
          <PictureOutlined
            key="details"
            onClick={() => showDetails(product)}
          />,
        ]}
      >
        <Meta
          title={
            <>
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                {product.nombre}
              </Title>
              <p className="prd-price">
                {product.nombre_rubro}
              </p>
            </>
          }
        />
        <p className="prd-descr">{product.informacion.substr(0, 25)}</p>
        <p className="prd-descr">{product.informacion.substr(25, 25)}</p>
        {len_dscr <= 25 ? <p className="prd-descr2">.</p> : null}
      </Card>
    </Col>
  );
}
