import SHK from "./ShkService";
import { showNotification } from "./ShowNotification";

//Descargar datos para el Productos
export const downloadDataProductos = async (id) => {
  try {
    let datos = await SHK.getProductos(id);
    
    if (datos.type > 0) {
      return datos;
    } else {
      showNotification("topRight", 2, "Error Inesperado", "Intente mas Tarde");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
