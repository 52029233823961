import { showNotification } from "./ShowNotification";
const URL_USER = "https://api.aal-estate.com/shk/api";

class SHK {
  //Datos de productos
  getProductos(id) {
    let body = new FormData();
    body.append("id", 1);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/1`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }


}

export default new SHK();
