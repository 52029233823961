import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Drawer,
  Popconfirm,
  Select,
  Typography,
  message,
  Divider,
  Tooltip,
  Button,
} from "antd";

//Creaciones de ID y Fecha
import { v4 as uuidv4 } from "uuid";
import moment from "moment";


//Iconos
import {
  DeleteOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  CalendarOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

//Componentes adicionales
import { TitlePage } from "../../components/TitlePage";
import MainHeader from "../../components/Shk/Header";
import ModalApp from "../../components/Modal";
import MainFooter from "../../components/Footer";
import ProductsCatalogo from "../../components/Shk/ProductsCatalog";
import ProductDetails from "../../components/Shk/ProductDetails";
import CardDetails from "../../components/Shk/CardDetails";
import TicketSearch from "../../components/Shk/TicketSearch";

//Producto actual para mostrar detalle
import { updateCurProductAction } from "../../actions/curProductActions";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";

//Estilos
import "./productos.scss";
import curStyle from "../../css/GlobalCmp.module.css";
import { colorSec, colorPrm } from "../../constants";
import { mdiNumeric9PlusCircle } from "@mdi/js";

export default function Productos(props) {
  const { setIsLoading } = props;
  const { Footer } = Layout;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [tipoModal, setTipoModal] = useState(1);
  const [widthModal, setWidthModal] = useState("700px");
  const [titleModal, setTitleModal] = useState();
  //States y Setters para lista de productos
  const [listProducts, setListProducts] = useState([]);
  
  const [searchProducto, setSearchProducto] = useState("");

  const { Content, Sider } = Layout;

  //////////// NUEVAS FUNCIONES DEL DISPATCH /////////////
  // utilizar use dispatch y te crea una función
  const dispatch = useDispatch();
  //Actualizar el current Producto para mostrar detalle
  const updateCurProduct = (record) => dispatch(updateCurProductAction(record));

  //Listado de productos
  const stockProductos = useSelector((state) => state.productos.productos);
  const [dataPro, setDataPro] = useState([]);

  //Busqueda de productos
  useEffect(() => {
    if (searchProducto) {
      (async () => {
        const filteredProducto = dataPro.filter((producto) => {
          let productoLowercase = producto.nombre.toLowerCase();

          let searchLowercase = searchProducto.toLowerCase();
          return productoLowercase.indexOf(searchLowercase) > -1;
        });
        setListProducts(filteredProducto);
        filteredProducto.length === 0
          ? message.warning("NO SE ENCONTAROS RESULTADOS", 1)
          : message.success(
              filteredProducto.length + " Producto(s) encontrado(s)",
              1
            );
      })();
    } else {
      setListProducts([]);
      message.warning("NO SE ENCONTAROS RESULTADOS", 1);
    }
  }, [searchProducto]);

  //Se ingresan valores de busqueda de items
  const onchangeSearch = async (value) => {
    setSearchProducto(value);
  };

  //Se muestran los detalles del Item
  const showDetails = async (record) => {
    updateCurProduct(record);
    openModal(null, 10, "700px");
  };

  //Se actualiza el Barcode del Item
  const showCard = async (record) => {
    updateCurProduct(record);
    openModal(null, 5, "700px");
  };

  // MODALES/////////////////////
  const openModal = (title, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setIsVisibleModal(true);
    setTitleModal(title);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };
  //FIN MODLAES////////////////

return (<Layout style={{ minHeight: "100vh" }}>
<TitlePage title="SHK Productos" />
<div style={{ height: "50" }}>
  <MainHeader
    setIsLoading={setIsLoading}
    setDataPro={setDataPro}
  />
</div>
<Row
  style={{ background: "#BABEBE" }}
>
  <Col xl={24} lg={12} md={12} sm={12} xs={4}>
    <div
      className="site-layout-background"
      style={{
        padding: 2,
        textAlign: "center",
        height: window.innerHeight - 100,
        overflow: "auto",
        backgroundColor: "#BABEBE",
      }}
    >
      <TicketSearch
        onchangeSearch={onchangeSearch}
      />
      <ProductsCatalogo
        listProducts={listProducts}
        showDetails={showDetails}
        showCard={showCard}
      />
    </div>
  </Col>
</Row>
      <Footer
      style={{
        textAlign: "center",
        bottom: 0,
        maxHeight: 50,
        paddingBottom: 15,
        paddingTop: 15,
        backgroundColor: "#ffffff",
      }}
    >
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 5 ? (
          <CardDetails closeModal={closeModal} varEffec={Math.random()} />
        ) : (
          <ProductDetails closeModal={closeModal} varEffec={Math.random()} />
        )}
      </ModalApp>
      <MainFooter setIsLoading={setIsLoading} curFecha={new Date()} />
    </Footer>
  </Layout>
);
}