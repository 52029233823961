import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Space,
  Badge,
  Dropdown,
  List,
  message,
  Typography,
  Button,
  Modal as ModalB,
} from "antd";

import Icon from "@mdi/react";
import {
  mdiLogout,
  mdiBasketOutline,
  mdiBasketOffOutline,
  mdiCloudDownloadOutline,
  mdiCloudUploadOutline,
  mdiCashPlus,
  mdiCashMinus,
  mdiRefresh,
  mdiLockReset,
} from "@mdi/js";

import {
  BellOutlined,
  MenuOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";

import moment from "moment";

import { ExclamationCircleOutlined } from "@ant-design/icons";

//Firebase
//import firebase from "../../../utils/Firebase";
//import "firebase/auth";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { productosAction } from "../../../actions/productosActions";

//Funciones propias
import { downloadDataProductos } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";

//Imaganes y logos
import logo_title from "../../../assets/img/logo-menu.png";
import { colorPrm } from "../../../constants";

//Estilos
import "./Header.scss";
import curStyle from "../../../css/GlobalCmp.module.css";

export default function Header(props) {
  const [userOptions, setUserOptions] = useState([]);

  const {
    setIsLoading,
    openModal,
    setDataPro
  } = props;

  const history = useHistory();

  //Ejecutar dispatch
  const dispatch = useDispatch();
  const ListadoProductos = (state) => dispatch(productosAction(state));

  const { Title } = Typography;
  const { confirm } = ModalB;

  // Carga de productos
  useEffect(() => {
    setIsLoading(true);
    (async () => {

      let reLoad = await downloadDataProductos(1);
      
      if (reLoad.type > 0) {
        //ListadoProductos(reLoad.productos);
        setDataPro(reLoad.productos);
        message.success("PRODUCTOS CARGADOS CON EXITO", 1);
      } else {
        message.warning("ERROR DE CONEXION... INTENTE MAS TARDE", 1);
      }
      
    })();
    setIsLoading(false);
  }, []);


  const opciones = [
    {
      key: "0",
      title: "Cambiar Password",
      icon: <Icon path={mdiLockReset} size={"26px"} color={colorPrm} />,
      onClick: () => openModal("Cambiar Password", 7, "500px"),
    },
    {
      key: "1",
      title: "Descargar Datos",
      icon: (
        <Icon path={mdiCloudDownloadOutline} size={"26px"} color={colorPrm} />
      ),
      onClick: () => console.log("Descargar Datos"),
    },
    {
      key: "6",
      title: "Cerrar Sesion",
      icon: <Icon path={mdiLogout} size={"26px"} color={colorPrm} />,
      onClick: () => console.log("Salir"),
    },
  ];

  const ShowAlerts = (
    <form
      style={{
        backgroundColor: "#ffffff",
        marginTop: "5px",
        width: "350px",
        padding: "5px",
      }}
    >
      <div>
        <List
          style={{ marginTop: 5 }}
          itemLayout="horizontal"
          dataSource={opciones}
          renderItem={(item) => (
            <List.Item style={{ marginBottom: 3, marginTop: 3, padding: 2 }}>
              <List.Item.Meta
                avatar={item.icon}
                title={
                  <Title
                    level={4}
                    style={{
                      padding: 2,
                      margin: 2,
                      fontWeight: 300,
                      fontSize: 18,
                    }}
                  >
                    {item.title}
                  </Title>
                }
                onClick={item.onClick}
              />
            </List.Item>
          )}
        />
      </div>
    </form>
  );

  return (
    <>
      <Row className="main-header">
        <span style={{ left: "10px", position: "absolute", top: 10 }}>
          {userOptions.length > 0 ? (
            <Space size={"middle"}>
              <Button
                type="primary"
                className={curStyle.btnWhite}
                shape="circle"
                icon={<MenuOutlined style={{ color: colorPrm }} />}
              />
              <img src={logo_title} alt="Imagen" className="img-logo-title" />
            </Space>
          ) : (
            <img src={logo_title} alt="Imagen" className="img-logo-title" />
          )}
        </span>
        <span style={{ textAlign: "center", top: 10 }}>
          <Title level={4} style={{ color: colorPrm, marginTop: 10 }}>
            {"SHOPCHECKPRO"}
          </Title>
        </span>
        <span
          className="li-options"
          style={{
            textAlign: "right",
            right: "5px",
            top: 10,
            position: "absolute",
            width: "750px",
          }}
        >
          <Space size={"middle"}>
            <Badge count={5} style={{ backgroundColor: "orange" }}>
              <Button
                type="primary"
                className={curStyle.btnWhite}
                shape="circle"
                icon={<BellOutlined style={{ color: colorPrm }} />}
              />
            </Badge>
            <Badge count={5} style={{ backgroundColor: "#108ee9" }}>
              <Button
                type="primary"
                className={curStyle.btnWhite}
                shape="circle"
                icon={<MailOutlined style={{ color: colorPrm, size: 24 }} />}
              />
            </Badge>
            Anibal Asprilla
            <Dropdown
              overlay={ShowAlerts}
              trigger={["click"]}
              placement="bottomLeft"
            >
              <Button
                type="primary"
                className={curStyle.btnSecundary}
                shape="circle"
                icon={<UserOutlined />}
              />
            </Dropdown>{/*
            {currentUser.menu_r ? (
              <Button
                type="primary"
                className={curStyle.btnWhite}
                onClick={() => openDrawer(2)}
                shape="circle"
                icon={<MenuOutlined style={{ color: "#52c41a", size: 24 }} />}
              />
            ) : null}*/}
          </Space>
        </span>
      </Row>
    </>
  );
}
